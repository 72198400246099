import { Card, Col, Drawer, Image, Layout, Row, Space, Statistic, Typography} from 'antd';
import SalesFunnel from './GoalsComponents/SalesFunnel';
import GoalsTimeline from './GoalsComponents/Timeline';
import CountUp from 'react-countup';
import { useState } from 'react';

  
const Goals = () => {
  const formatter = (value) => <CountUp end={value} separator="," />;

  const [selected_member, setSelectedMember] = useState(false);
  const [open, setOpen] = useState(false);

  const tiers = [
    {
      key: 1,
      name: "FieldOps™️  Tier 1",
      job: "Easy agri-operations tracking and Crop Growth Monitoring",
      brief: {
        email: "Irrigation Management", 
        profession: "Pest and Disease Early Warning"
      },
      bio: ""
    },{
      key: 2,
      name: "FieldOps™️  Tier 2",
      job: "Asset Management Integrations, Project Management Integrations",
      brief: {
        email: "", 
        profession: ""
      },
      bio: ""
    },{
      key: 3,
      name: "FieldOps™️  Tier 3",
      job: "Finance Management Integrations",
      brief: {
        email: "", 
        profession: ""
      },
      bio: ""
    }
  ]

  const showDrawer = (member) => {
    setOpen(true);
    setSelectedMember(member)

  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      overflow: "hidden",
      textAlign: "center",
      backgroundColor: "#18191b",
      padding: 10,
      float: "left",
      boxShadow: 'rgba(143, 168, 191) 0px -15px 15px -15px'
      }}>
        <div style={{ marginBottom: 20, width: "100%", textAlign: "left"}}>
          <Typography.Text style={{fontSize: "5vw", color: "#c9cbcf", fontWeight: "bold"}}>
            {/*<u style={{boxShadow: "inset 0 -0.5em hsla(134, 85%, 46%, 0.4)"}}></u>*/}
            Our Goals
          </Typography.Text>
        </div>

        <Row style={{padding: 10, height: "100%"}} gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Card 
              className="agci-card scroll"
              title="Market & Investor Value" 
              bordered={false}
            >
              <div>
              <Space direction='vertical' size="large" >
                <Space direction="horizontal" size="large">
                  <Statistic 
                    title="Agronomists"            
                    value={2.5}
                    prefix="~"
                    suffix="M"
                    formatter={formatter}
                  /> 
                  <Statistic 
                    title="Agronomist per Organization"            
                    value={10}
                    formatter={(value) => {
                      return (
                        <>
                          {formatter(10)}
                          -
                          {formatter(15)}
                        </>
                      )
                    }}
                  /> 
                  <Statistic 
                    title="CFO / Customers" 
                    prefix="~"
                    suffix="K"
                    value={250}
                    formatter={(value) => {
                      return (
                        <>
                          {formatter(150)}
                          -
                          {formatter(250)}
                        </>
                      )
                    }}
                  /> 
                </Space>
                <Space direction="horizontal" size="large">
                <Statistic 
                    title="SAM"            
                    value={750}
                    formatter={formatter}
                    prefix="€"
                    suffix="M"
                  />
                  <Statistic 
                    title="SOM"            
                    value={33}
                    formatter={formatter}
                    prefix="€"
                    suffix="M"
                  />
                   <Statistic 
                    title="Investor Value"            
                    value={30}
                    formatter={formatter}
                    suffix="x"
                  />
                  {/* <Statistic 
                    title="ARPU"            
                    value={35}
                    formatter={formatter}
                    prefix="€"
                    suffix="per month"
                  /> 
                  <Statistic 
                    title="Field Ops™️ ARR" 
                    value={50000}
                    formatter={formatter}
                    prefix="€"
                    suffix="per CFO"
                  />  */}
                  {/* <Statistic 
                    title="Agrocasco™️ ARR" 
                    formatter={formatter}
                    value={20000}
                    prefix="€"
                    suffix="per CFO"
                  />  */}
                </Space>
                <Typography.Paragraph>
                  CFO = Contract Farming Organization
                </Typography.Paragraph>
              </Space>
              </div>
            </Card>
            
            
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Card className='agci-card scroll'  title="Timeline" bordered={false}>
              <GoalsTimeline onClick={showDrawer} tiers={tiers}/>
            </Card>
          </Col>
        </Row>

        <Drawer
          title={selected_member?.name} 
          placement="right" size="large" 
          rootStyle={{zIndex: 1500}} 
          onClose={onClose} 
          open={open}
        >
          { selected_member &&
            <Card 
              key={selected_member.key} 
            >
              <Card.Meta
                title={<Typography.Text style={{ fontSize: "15pt",color: "#18191B",  whiteSpace: "wrap"}}>{selected_member.name}</Typography.Text>}
                description={
                  <Space direction='vertical'>
                    <Typography.Text>{selected_member.job}</Typography.Text>
                    <Typography.Text>{selected_member.brief?.profession}</Typography.Text>
                    <Typography.Text>{selected_member.brief?.email}</Typography.Text>
                    
                  </Space>
                }
              />
                <Typography.Paragraph style={{fontSize: 17, marginTop: 20, textJustify:"inter-word"}}>
                  <div dangerouslySetInnerHTML={{ __html: selected_member.bio }}></div>
                </Typography.Paragraph>
            </Card>
            }
          
        </Drawer>
    </Layout>
  );
};

export default Goals;