
import { DualAxes, Pie} from '@ant-design/charts';
import { Card, Drawer, Layout, Statistic, Typography} from 'antd';
import { useState } from 'react';
import CountUp from 'react-countup';

const formatter = (value) => {
  return (
  <Typography.Text>
    <Typography.Text>$</Typography.Text>
      <CountUp end={value} separator="," />
    <Typography.Text>B</Typography.Text>
  </Typography.Text>
  )
};
const CFTAM = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const data = [
    {
      type: 'Global Value',
      value: 300,
    },
  ];

  const config = {
    //appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color:['#12d940'],
    legend: false,

    radius: 1,
    innerRadius: 0.6,
    label: false,
    statistic: {
      title: false,
      content: {
        style: {
          //...
        },
        content: '$300B', // Here you can add custom label inside donut pie chart
      },
    }, 
    interactions: [
      {
        type: 'element-active',
      },
    ],
    pieStyle: {
      lineWidth: 0,
    },
  };
  return (
    <>
      <Card
        className="agci-card"
        style={{
          flex: 1,
          //zIndex: open ? 1500 : "",
        }}
        onClick={showDrawer}
        cover={
          <div style={{padding: 10}} >
            <Pie {...config} /> 
          </div>
        }
      >
        <Card.Meta 
          title={
            <Typography.Text>
              Global Value of Contract Farming in 2015
            </Typography.Text>
          }
          description={
            <Typography.Text>
              The International Food Policy Research Institute (IFPRI) estimated that the global value of contract farming was $300 billion.
            </Typography.Text>
          } 
        />
      </Card>

      <Drawer 
        className="drawer-full-page" 
        title="Index Insurance for Agricultural Transformation in Africa" 
        placement="bottom" 
        rootStyle={{width: "100vw", height: "100vh"}} 
        onClose={onClose} 
        size='large' 
        open={open}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </>
    
  );
};

export default CFTAM;