
import { HeartOutlined } from '@ant-design/icons';
import { faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image, Layout, Space, Typography} from 'antd';
import QueueAnim from 'rc-queue-anim';
import TextyAnim from 'rc-texty';
import { Textfit } from 'react-textfit';
import Typewriter from "typewriter-effect";
  
const Subintro = () => {

  return (
    <Layout className="subintro">
      <QueueAnim component="div" delay={100} componentProps={{style: { marginBottom: 10, width: "100%", textAlign: "left"}}}>
          <Typography.Title key={1} level={1} style={{color: "#18191B", fontWeight: "bold"}}>
            The Pitch            
          </Typography.Title>
        </QueueAnim>
        <iframe iv_load_policy="1" style={{width: "75%", height: "100%", textAlign: "center", margin: "auto auto", borderRadius: "16px", boxShadow: "-1px -1px 10px -10px rgba(0, 0, 0, 0.16)"}} src="https://www.youtube.com/embed/xVqK8FIB7ZE?si=HMABA78OSM8dBkdr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </Layout>
  );
};

export default Subintro;