
import { Card, Col, Drawer, Image, Layout, Row, Space, Typography} from 'antd';

import { HeartOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';

import "../HomeView.css";
import { useState } from 'react';

const Founders = () => {
  const [selected_member, setSelectedMember] = useState(false);
  const [open, setOpen] = useState(false);

  const founders = [
    {
      key: 1,
      name: "Metin Emenullahi",
      job: "CEO @ Agcurate",
      brief: {
        email: "metin [at] agcurate [dot] com", 
        profession: "Entrepreneur, B2B / B2C SaaS Products"
      },
      avatar: "/assets/founders_images/Metin_bg.png",
      bio: "Born in the city of Lankaran, Azerbaijan Metin Emenullahi embarked on his journey as a young entrepreneur. His fascination with computers and electronic products started from a very early age. After completing his primary, middle, and high school education in Azerbaijan, he moved to Ankara in 2008 to pursue his university education. While at the Middle East Technical University (METU), studying Computer Engineering, he seamlessly blended his passion for product development with his entrepreneurial spirit within the confines of the Animation Technologies and Game Development Center (ATOM) at METU Technopark.<br /><br />Metin's career commenced with a focus on web and game technologies. Over the ensuing 15 years, he has done over 30 software products across diverse sectors, including Information Security Technologies, Consumer Applications, Smart Home, Music Tech, and Agriculture. He also took the lead in numerous national and international research and development projects. At the present, his focus is on leveraging technology to significantly enhance the agriculture globally and create innovative solutions to meet the needs of agricultural stakeholders throughout the growing season."
      //As a member of the community at the European Space Agency's Space Business Incubation Center in the Netherlands, he has represented Agcurate in various programs within the agriculture category and closely witnessed the development of products and technologies in this field.<br /><br />Moreover, through professional engagements with organizations like EARSC, EUSPA, Start-Life, and Google Amsterdam, he maintains active contacts in the field of space technologies and the agriculture sector across the Netherlands, Belgium, the Czech Republic, and Europe as a whole. These connections enable Agcurate to stay at the forefront of the global technology race and make optimal strategic decisions based on the latest information.
    },{
      key: 2,
      name: "Berk Ülker",
      job: "CAIO @ Agcurate",
      brief: {email: "berk [at] agcurate [dot] com", profession: "Computer Vision & AI Expert, PhD Candidate"},
      avatar: "/assets/founders_images/Berk_bg.png",
      bio: "Berk Ülker graduated from the Middle East Technical University with a degree in Electrical and Electronics Engineering in 2012. From 2012 to 2017, he honed his expertise in image processing software development as a part of the ASELSAN Microelectronics, Guidance, and Electro-Optics Group. He completed his master's studies in 2015, focusing on image processing-based smart transportation solutions, and gained entrepreneurial experience with a team that included Osman Baytaroğlu.<br /><br />In 2017, Berk ventured into the field of semiconductor design and production by joining Nexperia BV in the Netherlands, where he made contributions to the development of image processing software. In 2018, he continued his academic journey by becoming a researcher at Eindhoven University of Technology and embarking on doctoral studies in the field of artificial intelligence. In 2020, he assumed the role of co-founder responsible for artificial intelligence at Agcurate, all while concurrently working towards the completion of his doctoral thesis. Berk has notable publications in international peer-reviewed journals and holds patents in his name."
    }
  ]

  const showDrawer = (member) => {
    setOpen(true);
    setSelectedMember(member)

  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      //height: "100vh",
      overflow: "scroll",
      padding: 10,
      float: "left",
      //boxShadow: 'rgba(143, 168, 191) 0px 15px 15px -15px'
      }}>
        <QueueAnim component="div" delay={100} componentProps={{style: { marginBottom: 10, width: "100%", textAlign: "left"}}}>
          <Typography.Title key={1} level={1} style={{color: "#18191B", fontWeight: "bold"}}>
            Founded with <HeartOutlined style={{color: "red"}} /> by 
          </Typography.Title>
        </QueueAnim>
        
        <QueueAnim component={Row} delay={300} componentProps={{className:"founders", gutter:16}} type="scale">
        <Col key={4} xs={0} sm={0} md={0} lg={2} xl={2}></Col>
          
          <Col key={3} className="founder" xs={24} sm={12} md={12} lg={10} xl={8}>
            <Card hoverable onClick={() => showDrawer(founders[0])}>
              <div className='founder-image'>
                <Image preview={false}  style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/Metin_bg.png"/>
              </div>
              <div className="founder-info">
                <Typography.Paragraph >
                  <Typography.Text style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Metin Emenullahi
                  </Typography.Text>
                  <br />
                  <Typography.Text italic bold style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    CEO
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Computer Science
                </Typography.Paragraph>
                <Typography.Paragraph>
                  13y in startups | B2B, B2C strategy
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Product & Technology
                </Typography.Paragraph>
              </div>
            </Card>
          </Col>
          <Col key={5} className="founder" xs={24} sm={12} md={12} lg={10} xl={8}>
            <Card hoverable onClick={() => showDrawer(founders[1])}>
              <div className='founder-image'>
                <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/Berk_bg.png"/>
              </div>
              <div className="founder-info">
              <Typography.Paragraph >
                  <Typography.Text style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Berk Ülker
                  </Typography.Text>
                  <br />
                  <Typography.Text italic bold style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    CAIO
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Electrical & Electronics Eng.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Ph D. Deep Learning
                </Typography.Paragraph>
                <Typography.Paragraph>
                  11y in ML @startups @enterprise @academy
                </Typography.Paragraph>
              </div>  
            </Card>
          </Col>
          <Col key={4} xs={0} sm={0} md={0} lg={2} xl={2}></Col>
        </QueueAnim>

        {/*<QueueAnim component="div" delay={500} componentProps={{style: { marginBottom: 10, width: "100%", textAlign: "left"}}}>
          <Typography.Title key={1} level={3} style={{ color: "#18191B", fontWeight: "bold"}}>
              Our Advisors & Mentors
          </Typography.Title>
        </QueueAnim>
        <div style={{ width: "90%", margin: "0 auto"}}>
          <QueueAnim component={Row} delay={700}  componentProps={{ className:"advisors",gutter:16}} type="scale">
            <Col key={1} className="advisor" xs={24} sm={12} md={6} lg={6} xl={6}>
              <Card hoverable>
                <div className='advisor-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/EnderErtugral.png"/>
                </div>
                <div class="advisor-info">
                  <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Ender Ertuğral
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    <Typography.Text>Director of CX & Partnership  </Typography.Text>
                    <br />
                    <Typography.Text>AXA Insurance </Typography.Text>
                    <br />
                    <Typography.Text>22y in Insurance Industry </Typography.Text>
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
            <Col key={2} className="advisor" xs={24} sm={12} md={6} lg={6} xl={6}>
              <Card hoverable>
                <div className='advisor-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/RomanShynkarenko.png"/>
                </div>
                <div class="advisor-info">
                  <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Roman Shynkarenko
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    <Typography.Text> Design & Management of 25+ Crop Insurance Programs </Typography.Text>
                    <br />
                    <Typography.Text> 21y in Crop Insurance </Typography.Text>
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
            <Col key={3} className="advisor" xs={24} sm={12} md={6} lg={6} xl={6}>
              <Card hoverable>
                <div className='advisor-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/DidierShapiro.png"/>
                </div>
                <div class="advisor-info">
                  <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Didier Shapiro
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    <Typography.Text> Growth, People and Sales</Typography.Text>
                    <br />
                    <Typography.Text>Founder at The Sales Doctor</Typography.Text>
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
            <Col key={4} className="advisor" xs={24} sm={12} md={6} lg={6} xl={6}>
              <Card hoverable>
                <div className='advisor-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/IvanaSediva.png"/>
                </div>
                <div class="advisor-info">
                  <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Ivana Sediva
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    <Typography.Text>Branding, Marketing, Negotiation</Typography.Text>
                    <br />
                    <Typography.Text>Ex-Country & Region CMO</Typography.Text>
                    <br />
                    <Typography.Text>McDonalds, Tesco, J&J </Typography.Text>
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
          </QueueAnim>

          <QueueAnim component={Row} componentProps={{className:"advisors", gutter: 16}} type="scale" delay={900}>
            <Col key={1} className="advisor-2" xs={24} sm={12} md={8} lg={8} xl={8}>
                <Card hoverable>
                  <div className='advisor-image'>
                    <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/GokberkCinbis.png"/>
                  </div>
                  <div class="advisor-info">
                    <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                      Gokberk Cinbis
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      Remote Sensing, Deep Learning
                    </Typography.Paragraph>
                  </div>
                </Card>
              </Col>
              <Col key={2} className="advisor-2" xs={24} sm={12} md={8} lg={8} xl={8}>
              <Card hoverable>
                <div className='advisor-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/ElifSertel.png"/>
                </div>
                <div class="advisor-info">
                  <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Elif Sertel
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Remote Sensing, GIS, Deep Learning 
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
            <Col key={3} className="advisor-2" xs={24} sm={12} md={8} lg={8} xl={8}>
              <Card hoverable>
                <div className='advisor-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src="/assets/founders_images/MustafaAydogdu.png"/>
                </div>
                <div class="advisor-info">
                  <Typography.Paragraph style={{fontSize: "15pt", color: "#18191B", fontWeight: "bold"}}>
                    Mustafa Aydogdu
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Agronomy, Data Collection
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Willingness to Pay of Farmers
                  </Typography.Paragraph>
                </div>
              </Card>
            </Col>
          </QueueAnim>
      </div>*/}

        <Drawer  
          title={selected_member?.name} 
          placement="right" size="large" 
          rootStyle={{zIndex: 1500}} 
          onClose={onClose} 
          open={open}
        >
          { selected_member &&
            <Card 
              key={selected_member.key} 
              bordered={false}
              className="founder-card-drawer"
            >
              <Card.Meta
                avatar={
                <div className='founder-image'>
                  <Image preview={false} style={{border: "1px solid #d0fbda", borderRadius: "50%"}} src={selected_member.avatar}/>
                </div>
                }
                title={<Typography.Text style={{ fontSize: "15pt",color: "#18191B",  whiteSpace: "wrap"}}>{selected_member.name}</Typography.Text>}
                description={
                  <Space direction='vertical'>
                    <Typography.Text>{selected_member.job}</Typography.Text>
                    <Typography.Text>{selected_member.brief?.profession}</Typography.Text>
                    <Typography.Text>{selected_member.brief?.email}</Typography.Text>
                    
                  </Space>
                }
              />
                <Typography.Paragraph style={{fontSize: 17, marginTop: 20, textJustify:"inter-word"}}>
                  <div dangerouslySetInnerHTML={{ __html: selected_member.bio }}></div>
                </Typography.Paragraph>
            </Card>
            }
          
        </Drawer>
    </Layout>

  );
};

export default Founders;