
import {  Layout, Space, Typography} from 'antd';
import React from 'react';

const data = Array.from({ length: 3 }).map((_, i) => ({
  href: 'https://ant.design',
  title: `ant design part ${i}`,
  avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
  description:
    'Ant Design, a design language for background applications, is refined by Ant UED Team.',
  content:
    'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
  
const description = 'This is a description.';


const Fundraising = ({innerRef}) => {


  return (
    <Layout ref={innerRef} style={{ 
      position: "relative",
      width: "100vw",
      height: "50vh",
      overflow: "hidden",
      textAlign: "left",
      backgroundColor: "#f7f7f8",
      padding: 10,
      float: "left"
     }}
    >

      <Layout style={{width: "80%", margin: "0 auto", position: "relative", padding: 10, backgroundColor: "transparent"}}>
        
          <Typography.Text style={{fontSize: "3vw", color: "#18191b", fontWeight: "bold"}}>
            Leave your email, we'll contact shortly
          </Typography.Text>

          
          {/*<iframe src="https://drive.google.com/embeddedfolderview?id=1DpS_ChSlD2DUTJPB_EWx8i9BRfybHH2r" title="GDrive" width="600" height="500" frameborder="0"></iframe>*/}
        
      </Layout>
      <Layout style={{ 
      position: "relative",

      overflow: "hidden",
      textAlign: "left",
      backgroundColor: "#f7f7f8",
      padding: 10,
      float: "left",
      zIndex: 1510,
     }}>
      {/* <iframe 
            style={{width: "100%", height: "100%", flex:1}}
            title='Agcurate Library' 
            frameBorder={0}
            src="https://gpt.agcurate.com"></iframe> */}


        <div style={{height: "100vh"}}>
        
        </div>
      </Layout>


      


    </Layout>
  );
};

export default Fundraising;