
import { Pie } from '@ant-design/charts';
import { Card, Drawer, Layout, Typography} from 'antd';
import TweenOne from 'rc-tween-one';
import { useState } from 'react';
import PDFViewer from '../../../../Components/PDFViewer/PDFViewer';

  
const ContractFarmingMarketShare = () => {
  
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };


  const data = [
    {
      type: 'Contract Farming',
      value: 39,
    },
    {
      type: 'Traditional Agriculture',
      value: 61,
    },
  ];

  const config = {
    //appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color:['#12d940','#d0fbda'],
    legend: {
      layout: 'vertical',
      position: 'bottom'
    },
    label: {
      type: 'inner',
      offset: '-8%',
      content: '{value}%',
      style: {
        fontSize: 18,
        fill: "#3c3f44"
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    pieStyle: {
      lineWidth: 0,
    },
  };
  return (
    <>
      <Card
        className="agci-card"
        style={{
          flex: 1,
          //zIndex: open ? 1500 : "",
        }}
        onClick={showDrawer}
        cover={
          <div style={{padding: 10}} >
            <Pie {...config} />
          </div>
        }
      >
        <Card.Meta 
          title={
            <Typography.Text>
              Contract Farming Demand in US
            </Typography.Text>
          }
          description={
            <Typography.Text>
              Food & Agriculture Organization (2008)
            </Typography.Text>
          } 
        />
      </Card>
      <Drawer 
        className="drawer-full-page" 
        title="Index Insurance for Agricultural Transformation in Africa" 
        placement="bottom" 
        rootStyle={{width: "100vw", height: "100vh"}} 
        onClose={onClose} 
        size='large' 
        open={open}
      >
        <PDFViewer url="/assets/pdf/IndexInsuranceforAgriculturalTransformationinAfrica.pdf"/>
      </Drawer>
    </>
  );
};

export default ContractFarmingMarketShare;