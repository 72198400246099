
import { Button, Drawer, Image, Layout, Modal, Space, Typography} from 'antd'
import { useState } from 'react';

const Tagline = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    //setUrl(url);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      overflow: "hidden",
      textAlign: "center",
      verticalAlign: "middle",
      display: "table-cell",
      padding: 10,
      zIndex: 1,
      boxShadow: 'rgba(143, 168, 191) 0px 15px 15px -15px'
      }}>

       
      <Typography.Paragraph 
        style={{
          fontSize: "4.5vw", 
          fontWeight: 600, 
          //color: "#3c3f44",
          color: "#18191b",

          display: "initial"}}>
          We make agriculture <u onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>investable</u>, <u onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>sustainable</u> and <u onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}>resilient against climate</u>
          
          
          {/* <span style={{fontSize: "20pt"}}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
            by empowering field supervisors & agronomists at contract farming organizations with 
          powerful technology tools and protecting the crop production against climate risks by providing satellite-driven risk mitigation instruments.*/}
      </Typography.Paragraph>
      
      <Typography.Paragraph>
        <Button
          className="agci-button tagline-button"
          
          onClick={() => showDrawer()}>
          <Typography.Text style={{fontWeight: 600, color: "#ffffff"}}>  
            SEE HOW
          </Typography.Text>
        </Button>
      </Typography.Paragraph>

      <Modal width={"70%"} destroyOnClose styles={{body: { height: "70vh"}}} title="Agcurate Elevator Pitch" footer={null} onCancel={onClose} open={open}>
        {/* <iframe 
          style={{width: "100%", height: "100%", flex:1}}
          title='Agcurate Blog' 
          frameBorder={0}
          src={url}></iframe> */}
          <iframe style={{width: "100%", height: "100%"}} src="https://www.youtube.com/embed/Ey1Tu9lfC8A?si=iCha5vt23KtZ2bm7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Modal>

    </Layout>
  );
};

export default Tagline;