
import { faArrowUpRightFromSquare, faCaretDown, faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Image, Layout, Modal, Space, Typography} from 'antd';
import QueueAnim from 'rc-queue-anim';
import TextyAnim from 'rc-texty';
import { useState } from 'react';
import Typewriter from "typewriter-effect";

  
const FundraisingRibbon = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      overflow: "hidden",
      textAlign: "center",
      verticalAlign: "middle",
      display: "table-cell",
      padding: 10,
      zIndex: 1,
      boxShadow: 'rgba(255,255,255) 0px 15px 15px -15px, rgba(255,255,255) 0px -15px 15px -15px'
      }}>

       
      <Typography.Paragraph 
        style={{
          fontSize: "4.5vw", 
          fontWeight: 600, 
          //color: "#3c3f44",
          color: "#18191b",

          display: "initial"
        }}
      >
          We are currently <u onClick={showDrawer}>fundraising!</u>
      </Typography.Paragraph>
      
      <Typography.Paragraph>
        <Button
          className="agci-button tagline-button"
          
          onClick={showDrawer}>
          <Typography.Text style={{fontWeight: 600, color: "#ffffff"}}>  
            Join Us
          </Typography.Text>
        </Button>
      </Typography.Paragraph>
      {/* <Drawer title="Agcurate Blog" placement="right" size="large" onClose={onClose} open={open}>
        <iframe 
          style={{width: "100%", height: "100%", flex:1}}
          title='Agcurate Blog' 
          frameBorder={0}
          src="https://blog.agcurate.com/contract-farming-meets-innovation/"></iframe>
      </Drawer> */}

        <Modal width={"70%"} destroyOnClose styles={{body: { height: "70vh"}}} title="Agcurate Pitch with 'The Ask'" footer={null} onCancel={onClose} open={open}>
        {/* <iframe 
          style={{width: "100%", height: "100%", flex:1}}
          title='Agcurate Blog' 
          frameBorder={0}
          src={url}></iframe> */}
          <iframe style={{width: "100%", height: "100%"}} src="https://www.youtube.com/embed/rnYO2-ow8J4?si=TFeViP1ZgfB3iNAP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoplay="1" allowfullscreen></iframe>
      </Modal>
    </Layout>
  );
};

export default FundraisingRibbon;