
import { HeartOutlined } from '@ant-design/icons';
import { faArrowUpRightFromSquare, faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Drawer, Layout, Row, Steps, Typography, message, theme} from 'antd';
import QueueAnim from 'rc-queue-anim';
import TextyAnim from 'rc-texty';
import TweenOne from 'rc-tween-one';
import { useState } from 'react';
import Typewriter from "typewriter-effect";
import Analytics from '../../../analytics/analytics';

  
const Backstory = () => {
  const [url, setUrl] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = (url) => {
    setOpen(true);
    setUrl(url)

  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      height: "85vh",
      overflow: "scroll",
      textAlign: "center",
      backgroundColor: "#ffffff",
      padding: 10,
      boxShadow: 'inset rgba(143, 168, 191) 0px -15px 15px -15px,inset rgba(143, 168, 191) 0px 15px 15px -15px',
      
      }}>
        <Layout style={{width: "80%", margin: "0 auto", position: "relative", padding: 10, backgroundColor: "transparent"}}>
        <div style={{ marginBottom: 20, width: "100%", textAlign: "left"}}>
          <Typography.Text 
            style={{
              fontSize: "5vw",
              //color: "#d0fbda",
              color: "#18191b",
              fontWeight: "bold"}}>
            A little backstory
          </Typography.Text>
        </div>
        <QueueAnim type="bottom" component={Row} componentProps={{type:"flex", gutter: 16}}  delay={300}>
          <Col key={1} style={{ display: "flex"}} xs={24} sm={24} md={24} lg={8} xl={8}>
            <Card
              className="agci-card"
              onClick={() => showDrawer("https://blog.agcurate.com/agriculture-as-a-business/")}
              title={
                <Typography.Text  style={{color: "#3c3f44", fontSize: "14pt"}}>
                  Agriculture
                </Typography.Text>
              }
              bordered={false}>
              <Typography.Paragraph style={{color: "#3c3f44",fontSize: "12pt"}}> 
                Agriculture is the way we grow plants and raise animals to produce the food we eat and materials we use. Farmers work hard on farms, which are big pieces of land, to grow crops like rice, wheat, and vegetables. They also take care of animals like cows, pigs, and chickens to get meat, milk, and eggs. 
              </Typography.Paragraph>
              <Typography.Paragraph  style={{color: "#3c3f44",fontSize: "12pt"}}>                 
                To grow crops, farmers need to prepare the soil, plant seeds, and give water and nutrients to help the plants grow big and healthy. 
                They also protect their crops from pests and bad weather. 
                When the crops are ready, farmers harvest them by picking the fruits or cutting the plants. 
              </Typography.Paragraph>
              <Typography.Paragraph  style={{color: "#3c3f44",fontSize: "12pt"}}>
                After that, the food goes to markets and stores for us to buy and enjoy! 
                Agriculture is super important because it provides us with all the delicious and nutritious food we need to stay healthy and happy!
              </Typography.Paragraph>
            </Card>
          </Col>
          <Col key={2} style={{ display: "flex"}}  xs={24} sm={24} md={24} lg={8} xl={8}>
            <Card
              className="agci-card"
              onClick={() => showDrawer("https://blog.agcurate.com/what-is-contract-farming/")}
              title={
                <Typography.Text  style={{color: "#3c3f44",fontSize: "14pt"}}>
                  Contract Farming
                </Typography.Text>
              }
              bordered={false}>
              <Typography.Paragraph  style={{color: "#3c3f44", fontSize: "12pt"}}> 
                Contract farming is when farmers and companies make a deal before the crops are even grown. 
                The companies promise to buy the crops from the farmers, and in return, the farmers agree to follow certain rules and use specific techniques to grow the crops. 
              </Typography.Paragraph>
              <Typography.Paragraph  style={{color: "#3c3f44", fontSize: "12pt"}}> 
                This helps the farmers because they know they'll have buyers for their crops, and it helps the companies because they get a steady supply of the crops they need
              </Typography.Paragraph>
              <Typography.Paragraph style={{color: "#3c3f44", fontSize: "12pt"}}>
                With contract farming, both the farmers and the companies can trust each other, and this creates a stable and reliable market where everyone knows what to expect. 
                It's a win-win situation that helps everyone involved and makes agriculture more secure and attractive for investors too!
              </Typography.Paragraph>
            </Card>
          </Col>
          <Col key={3} style={{ display: "flex"}}  xs={24} sm={24} md={24} lg={8} xl={8}>
            <Card
              className="agci-card"
              onClick={() => showDrawer("https://blog.agcurate.com/investable-agriculture/")}
              title={
              <Typography.Text  style={{color: "#3c3f44",fontSize: "14pt"}}>
                Investable Agriculture & Future
              </Typography.Text>
              }
              bordered={false}>
              <Typography.Paragraph  style={{color: "#3c3f44",fontSize: "12pt"}}>
                When we support contract farming, we encourage more companies to work with farmers, making farming a safer and more reliable investment for everyone involved. 
              </Typography.Paragraph>
              <Typography.Paragraph  style={{color: "#3c3f44",fontSize: "12pt"}}>
                As a result, both farmers and contract farming organizations not only become profitable, but also ROIs become calculable and food system becomes healthy & manageable. 
              </Typography.Paragraph>
              <Typography.Paragraph  style={{color: "#3c3f44", fontSize: "12pt"}}>
                In this sense, we think contract farming is the backbone & future of agriculture. Empowering contract farming makes the future more sustainable.
              </Typography.Paragraph>
            </Card>
          </Col>
          </QueueAnim>
        </Layout>
        <Drawer className="drawer-full-page" title="Agcurate Blog" placement="bottom" size="large" rootStyle={{width: "100vw", height: "100vh"}} onClose={onClose} open={open}>
          <iframe 
            style={{width: "100%", height: "100%", flex:1}}
            title='Agcurate Blog' 
            frameBorder={0}
            src={url}></iframe>
        </Drawer>
    </Layout>
  );
};

export default Backstory;