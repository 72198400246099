
import { Layout, Typography} from 'antd';
import QueueAnim from 'rc-queue-anim';
import Typewriter from "typewriter-effect";

// Components
import Logo from '../../../Components/Logo/Logo';

import bg_video from "../../../bg_video.mp4";
  
const Intro = () => {

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      height: "100vh",
      overflow: "hidden",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      float: "left"
      }}>

      <video style={{objectFit: "cover", height: "100%", width: "100%"}} autoPlay loop muted>
          <source src={bg_video} type='video/mp4' />
      </video>

      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        //backgroundColor: 'rgba(18,217,64,0.4)', /* Use rgba for a semi-transparent overlay */
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: 1060,
        textAlign: "center"
      }}>
        <div style={{width: "100%", alignItems:"center", textAlign: "center", justifyContent: "center"}} >
          <div  style={{width: "60%"}}>
            <Logo type="light"/>
          </div>

          <div style={{textAlign: "left", bottom: 20, left: 20, position: "absolute", lineHeight: "1"}}>
            <QueueAnim>
            <div key="demo1" style={{fontSize: "5vw", color: "white", fontWeight: "bold"}}>Empowering</div>
            <div key="demo2" style={{fontSize: "5vw", color: "white", fontWeight: "bold"}}>
              <Typewriter
                options={{
                  loop: true
                }}
                onInit={(typewriter) => {
                  typewriter.changeDelay(100)
                    .typeString("Contract Farming").pauseFor(1000).deleteAll()
                    .typeString("Agriculture").pauseFor(1000).deleteAll()
                    .typeString("Food Supply Chain").pauseFor(1000).deleteAll()
                    .typeString("Farmers").pauseFor(1000).deleteAll()
                    .typeString("Global Food Production")
                    .start();
                }}
              />
            </div>
          </QueueAnim>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default Intro;