import React, { useState, useEffect } from 'react';
import { Funnel } from '@ant-design/plots';
import { CheckCircleFilled, ClockCircleFilled, ExportOutlined, QuestionCircleOutlined, SmileOutlined } from '@ant-design/icons';
import { Space, Spin, Statistic, Timeline, Tooltip, Typography } from 'antd';
import CountUp from 'react-countup';

const GoalsTimeline = (props) => {
  const formatter = (value) => <CountUp end={value} separator="," />
  const items = [
    {
      label: "2024",
      dot: <ClockCircleFilled />,
      color: "green",
      children: (
        <Space direction="horizontal" size="large">
             <Statistic 
            title="Fundraising"
            value="Pre-seed"
          />
          <Statistic 
            title={
              <a onClick={() => props.onClick(props.tiers[0])}>
              Release &nbsp;
              <Tooltip title="Click to open">
                <ExportOutlined />
              </Tooltip>
            </a>
            }
            value="Tier 1"
          />
          <Statistic 
            title={
              <>
                Hectares&nbsp;
                <Tooltip title="Hectares under management">
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }            
            value={10}
            formatter={formatter}
            suffix={"K ha"}
          /> 
          <Statistic 
            title="Pilots"
            value={50}
            formatter={formatter}
          /> 
          
          <Statistic 
            title="Regions"            
            value={`AZE, TR, NL`}
          />
          {/* <Statistic 
            title="Revenue"            
            value={}
            formatter={formatter}
            prefix="€"
          />  */}
          
        </Space>
      ),
    },
    {
      label: "2025",
      color: 'gray',
      children: (
        <Space direction="horizontal" size="large">
       
          <Statistic 
            title={
            <a onClick={() => props.onClick(props.tiers[1])}>
              Release &nbsp;
              <Tooltip title="Click to open">
                <ExportOutlined />
              </Tooltip>
            </a>
            }            
            value={"Tier 2"}
          /> 
           <Statistic 
            title={
              <>
                Hectares&nbsp;
                <Tooltip title="Hectares under management">
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }            
            value={35}
            formatter={formatter}
            suffix={"K ha"}
          /> 
          <Statistic 
            title="Pilots"
            value={150}
            formatter={formatter}
          />
           <Statistic 
            title="New Customers"
            value={120}
            formatter={formatter}
          />

         
          <Statistic 
            title="Regions"            
            value={`AZE, TR, NL, East. EU`}
          />
          {/* <Statistic 
            title="Agrocasco™️"            
            value={20000}
            formatter={formatter}
            prefix="€"
          />  */}
        </Space>
      ),
    },
    {
      label: "2026",
      color: "green",
      children: (
        <Space direction="horizontal" size="large">
           <Statistic 
            title="Fundraising"
            value="Series A"
          />
          <Statistic 
            title={
            <a onClick={() => props.onClick(props.tiers[2 ])}>
              Release &nbsp;
              <Tooltip title="Click to open">
                <ExportOutlined />
              </Tooltip>
            </a>
            }            
            value={"Tier 3"}
          /> 
           <Statistic 
            title={
              <>
                Hectares&nbsp;
                <Tooltip title="Hectares under management">
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }            
            value={100}
            formatter={formatter}
            suffix={"K ha"}
          /> 
          <Statistic 
            title="Customers"
            value={300}
            formatter={formatter}
          />
          <Statistic 
            title="Regions"            
            value={`NL, East. EU, SE Asia`}
          /> 
          {/* <Statistic 
            title="Agrocasco™️"            
            value={60000}
            formatter={formatter}
            prefix="€"
          />  */}
          {/* <Statistic 
            title="Ins. Policies (countries)" 
            formatter={formatter}
            value={3}
          />  */}
        </Space>
      ),
    },
    {
      label: "2027",
      color: "gray",
      children: (
        <Space direction="horizontal" size="large">
          <Statistic 
          title={
          <>
            Release &nbsp;
            <Tooltip title="Click to open">
              <ExportOutlined />
            </Tooltip>
          </>
          }            
          value={"Tier 4"}
        /> 
        <Statistic 
          title={
            <>
              Hectares&nbsp;
              <Tooltip title="Hectares under management">
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }            
          value={300}
          formatter={formatter}
          suffix={"K ha"}
        /> 
        <Statistic 
          title="Customers"
          value={500}
          formatter={formatter}
        />

      
        <Statistic 
          title="Regions"            
          value={`East. EU, SE Asia, LATAM`}
        />
      </Space>
      ),
    },
    {
      label: "2028",
      color: "green",
      children: (
        <Space direction="horizontal" size="large">
          <Statistic 
            title="Fundraising"
            value="Series B"
          />
          <Statistic 
          title={
          <>
            Release &nbsp;
            <Tooltip title="Click to open">
              <ExportOutlined />
            </Tooltip>
          </>
          }            
          value={"Tier 4"}
        /> 
        <Statistic 
          title={
            <>
              Hectares&nbsp;
              <Tooltip title="Hectares under management">
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }            
          value={500}
          formatter={formatter}
          suffix={"K ha"}
        /> 
        <Statistic 
          title="Customers"
          value={750}
          formatter={formatter}
        />

      
        <Statistic 
          title="Regions"            
          value={`East. EU, SE Asia, LATAM`}
        />
      </Space>
      ),
    },
    {
      label: "...",
      color: "gray",
      children: "...",
    }
  ];

  return (
      <Timeline className='agci-goals-timeline' mode='left' items={items}/>
    );
};


export default GoalsTimeline;
