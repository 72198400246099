
import {  Col, Layout, Row, Space, Typography} from 'antd';
import React from 'react';
import ContractFarmingMarketShare from './MarketCharts/ContractFarmingMarketSharePie';
import CAGR from './MarketCharts/CAGR';
import CFTAM from './MarketCharts/CF_TAM';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';


const data = Array.from({ length: 3 }).map((_, i) => ({
  href: 'https://ant.design',
  title: `ant design part ${i}`,
  avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
  description:
    'Ant Design, a design language for background applications, is refined by Ant UED Team.',
  content:
    'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
}));

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
  
const description = 'This is a description.';


const Market = () => {


  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw" ,
      overflow: "hidden",
      textAlign: "left",
      backgroundColor: "#f7f7f8",
      padding: 10,
      float: "left"
     }}
    >

      <Layout style={{width: "80%", margin: "0 auto", position: "relative", padding: 10, backgroundColor: "transparent"}}>
        <div style={{ marginBottom: 20, width: "100%", textAlign: "left"}}>
          <Typography.Text style={{fontSize: "5vw", color: "#18191b", fontWeight: "bold"}}>
            Current Market Status
          </Typography.Text>
          
        </div>
        {/* <QueueAnim type="bottom" delay={300} componentProps={{type:"flex", gutter: 16,  justify:"center"}}>
          <Typography.Title>
            Will be updated soon.
          </Typography.Title>
        </QueueAnim> */}
        <QueueAnim type="bottom" component={Row} delay={300} componentProps={{type:"flex", gutter: 16,  justify:"center"}}>
          <Col key={1} style={{ flex: 1, display: "flex"}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <ContractFarmingMarketShare />
          </Col>
          <Col key={2} style={{ flex: 1, display: "flex"}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <CAGR />
          </Col>
          <Col key={3} style={{ flex: 1, display: "flex"}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <CFTAM />
          </Col>
        </QueueAnim>


        <div style={{ marginBottom: 20, width: "100%", textAlign: "left"}}>
          <Typography.Text style={{fontSize: "3vw", color: "#18191b", fontWeight: "bold"}}>
            Case Studies
          </Typography.Text>
        </div>
        
        <QueueAnim type="bottom" component={Row} delay={300} componentProps={{type:"flex", gutter: 16,  justify:"center"}}>
          {/* <Col key={1} style={{ flex: 1, display: "flex"}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <ContractFarmingMarketShare />
          </Col>
          <Col key={2} style={{ flex: 1, display: "flex"}} xs={24} sm={24} md={8} lg={8} xl={8}>
          <ContractFarmingMarketShare />
          </Col>
          <Col key={3} style={{ flex: 1, display: "flex"}} xs={24} sm={24} md={8} lg={8} xl={8}>
          <CFTAM />
          </Col> */}
          <Typography.Title>
            Will be updated soon.
          </Typography.Title>
        </QueueAnim>

      </Layout>

    </Layout>
  );
};

export default Market;