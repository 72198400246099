import { Card, Carousel, Col, Drawer, Image, Layout, Row, Typography} from 'antd';
import { useState } from 'react';
  
const Supporters = () => {
  const [open, setOpen] = useState(false);
  const [supporter, setSupporter] = useState({url: "", title:""}); // to be used in the drawer

  const showDrawer = (supporter) => {
    setOpen(!open);

    switch (supporter) {
      case "google":
        setSupporter({url: "https://startup.google.com/", title: "Google for Startups: Climate Change"})
        break;
      case "startlife":
        setSupporter({url: "https://start-life.nl/startlife-picks-9-startups-building-toward-sustainable-agriculture/", title: "Start-Life Accelerator"})
        break;
      case "innowwide":
        setSupporter({url: "", title: "Innowwide"})
        break;
      case "eu":
        setSupporter({url: "", title: "EU"})
        break;
      case "ccai":
        setSupporter({url: "", title: "CCAI"})
        break;
      case "parsec":
        setSupporter({url: "https://parsec-accelerator.eu/portfolio-items/cottonsurance/", title: "PARSEC Accelerator"})
        break;
      case "euspa":
        setSupporter({url: "https://www.euspa.europa.eu/myeuspace-competition-finalists", title: "myEUSpace Competition"})
        break;
      case "copernicus":
        setSupporter({
          url: "https://www.copernicus.eu/en/documentation/information-material/copernicus-incubation-programme",
          title: "Copernicus Incubation"
        })
        break;
      default:
        break;
    }
  };

  const onClose = () => {
    setOpen(false);
    setSupporter({url: "", title: ""})
  };

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw",
      //height: "25vh",
      overflow: "hidden",
      padding: 10,
      float: "left",
      //boxShadow: 'rgba(255,255,255) 0px 15px 15px -15px, rgba(255,255,255) 0px -15px 15px -15px'
      boxShadow: 'inset rgba(143, 168, 191) 0px -15px 15px -15px, inset rgba(143, 168, 191) 0px 15px 15px -15px',
      }}>
        <Typography.Text style={{textAlign: "right", color: "#60626C", fontSize: "3vw", fontWeight: "bold"}}> 
          Supported By 
        </Typography.Text>
          <Carousel effect="scrollx" className='supporters-carousel' autoplay>
            <Row className="supporters-row" justify="space-between" align="horizontal" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("google")}>
                  <div className='supporters-image'>
                    <Image preview={false} src="/assets/supporters/google.png" />
                  </div>
                </Card>
              </Col>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("startlife")}>
                  <div className='supporters-image'>
                    <Image preview={false} src="/assets/supporters/startlife.png" />
                  </div>
                </Card>  
              </Col>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("innowwide")}>
                  <div className='supporters-image'>
                    <Image preview={false} src="/assets/supporters/Innowwide.png" />
                  </div>
                </Card>
              </Col>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("eu")}>
                  <div className='supporters-image'>
                    <Image  preview={false} src="/assets/supporters/EU.png" />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className='supporters-row' justify="space-between" align="horizontal" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("ccai")}>
                  <div className='supporters-image'>
                    <Image style={{width: "75%"}} preview={false} src="/assets/supporters/ccai2.png" />
                  </div>
                </Card>
              </Col>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("parsec")}>
                  <div className='supporters-image'>
                    <Image style={{width: "60%"}} preview={false} src="/assets/supporters/parsec.png" />
                  </div>
                </Card>
              </Col>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("euspa")}>
                  <div className='supporters-image'>
                    <Image style={{width: "60%"}} preview={false} src="/assets/supporters/euspa.png" />
                  </div>
                </Card>
              </Col>
              <Col className="supporters-col" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card className='supporters-card' hoverable onClick={()=>showDrawer("copernicus")}>
                  <div className='supporters-image'>
                    <Image preview={false} src="/assets/supporters/copernicus.png" />
                  </div>
                </Card>
              </Col>
            </Row>
          </Carousel>

          <Drawer 
            className="drawer-full-page" 
            title={supporter.title}
            placement="bottom" 
            rootStyle={{width: "100vw", height: "100vh", zIndex: 1600}} 
            onClose={onClose} 
            size='large' 
            open={open}
          >
           <iframe 
            style={{width: "100%", height: "100%", flex:1}}
            title={supporter.title} 
            frameBorder={0}
            src={supporter.url}></iframe>
        </Drawer>
    </Layout>
  );
};

export default Supporters;