
import { DualAxes} from '@ant-design/charts';
import { Card, Drawer, Layout, Typography} from 'antd';
import { useState } from 'react';

  
const CAGR = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const data = [
    {
      year: '2022',
      value: 347.5,
      growth: 349,
    },
    {
      year: '2024',
      value: 399.8,
      growth: 400,
    },
    {
      year: '2026',
      value: 457.8,
      growth: 460,
    },
    {
      year: '2028',
      value: 522.0,
      growth: 525,
    },
    {
      year: '2030',
      value: 577,
      growth: 580,
    },
  ];
  const config = {
    data: [data, data], 
    tooltip: false,
    xField: 'year',
    yField: ['value', 'growth'],
    seriesColor:['#ff0000', '#00ff00', '#0000ff'],
    yAxis: [
      {
        min: 0,
        max: 600,
        formatter: (value) => `${value}%`,
      },
      {
        min: 0,
        max: 600 ,
        formatter: (value) => `${value} points`,
      },
    ],
    geometryOptions: [
      {
        geometry: 'column',
        color:['#12d940'],
        style: {
          stroke: "#ffffff"
        }
      },
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };
  return (
    <>
    <Card
      className="agci-card"
      style={{
        flex: 1,
        //zIndex: open ? 1500 : "",
      }}
      onClick={showDrawer}
      cover={
        <div style={{padding: 10}} >
          <DualAxes {...config} />
        </div>
      }
    >
      <Card.Meta 
        title={
          <Typography.Text>
            Global CAGR 6.5%
          </Typography.Text>
        }
        description={
          <Typography.Text>
            The global contract farming market is expected to grow from $347.5 billion in 2022 to $516.3 billion in 2030, at a CAGR of 6.5%
          </Typography.Text>
        } 
      />
    </Card>

    <Drawer 
        className="drawer-full-page" 
        title="Index Insurance for Agricultural Transformation in Africa" 
        placement="bottom" 
        rootStyle={{width: "100vw", height: "100vh"}} 
        onClose={onClose} 
        size='large' 
        open={open}
      >
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Drawer>
    </>
  );
};

export default CAGR;