import { FloatButton, Layout, Space } from 'antd';
import logo from '../../logo.svg';
import './HomeView.css';

import Intro from './Sections/Intro';
import Founders from './Sections/Founders';
import Subintro from './Sections/Subintro';
import Backstory from './Sections/Backstory';
import Supporters from './Sections/Supporters';
import Traction from './Sections/Traction';
import Tagline from './Sections/Tagline';
import Market from './Sections/Market';
import Goals from './Sections/Goals';
import FundraisingRibbon from './Sections/FundraisingRibbon';
import Fundraising from './Sections/Fundraising';
import Footer from './Sections/Footer';
import { useRef } from 'react';

function HomeView() {
  const myRef = useRef(null);

  const handleClick = () => {
    console.log("SLAIDFBHK");
    myRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <div className="App">
      <div className="App-header">
          <Intro />
          <Supporters />  
          <Subintro />
          <Founders />
          <Backstory />
          <Tagline />
          <Market />
          <Goals />
          <FundraisingRibbon />
          <Traction/>
          {/* <Fundraising innerRef={myRef}/> */}
          <Footer />
      </div>
      
          <FloatButton onClick={handleClick} style={{zIndex: 1500}} />
          <FloatButton.BackTop style={{zIndex: 1500}} />
    </div>
  );
}

export default HomeView;
