
import { HeartOutlined } from '@ant-design/icons';
import { faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Divider, Image, Layout, Row, Steps, Typography, message, theme} from 'antd';
import QueueAnim from 'rc-queue-anim';
import TextyAnim from 'rc-texty';
import { useState } from 'react';
import Typewriter from "typewriter-effect";
import Logo from '../../../Components/Logo/Logo';

  
const Footer = () => {


  return (
    <Layout.Footer style={{
      position: "relative",
      width: "100vw",
      overflow: "hidden",
      backgroundColor: "#18191b",
      padding: 10,
      float: "left",
      boxShadow: 'rgba(143, 168, 191) 0px -15px 15px -15px',
    }}>
      <div  style={{paddingLeft: 10, width: "20%", position: "relative", margin: "auto 0"}}>
        <Logo type="light"/>
      </div>
      <Divider />
      <div  className="copyright" style={{paddingLeft: 10}}>
        © 2023 Agcurate BV. All rights reserved.
      
        <div className="footer-options">
          <a className="footer-contact" href="mailto:investors@agcurate.com">investors@agcurate.com</a>
          <ul className="footer-social">
            <li><a href="https://facebook.com/agcurate"><img src="/assets/icons/social/light/facebook.png" /></a></li>
            <li><a href="https://twitter.com/agcurate"><img src="/assets/icons/social/light/twitter.png" /></a></li>
            <li><a href="https://linkedin.com/company/agcurate"><img src="/assets/icons/social/light/linkedin.png" /></a></li>
          </ul>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;