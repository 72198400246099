
import { ExportOutlined, HeartOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { faHandPointLeft, faHandPointRight, faTree } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Layout, Row, Space, Statistic, Steps, Tooltip, Typography, message, theme} from 'antd';
import CountUp from 'react-countup';

  
const Traction = () => {
  const formatter = (value) => <CountUp end={value} separator="," />

  return (
    <Layout style={{ 
      position: "relative",
      width: "100vw",
      overflow: "hidden",
      textAlign: "center",
      justifyContent: "center",
      backgroundColor: "#c9cbcf",
      padding: 10,
      float: "left",
      boxShadow: 'rgba(143, 168, 191) 0px -15px 15px -15px'
      }}>
        <div style={{ marginBottom: 20, width: "100%", textAlign: "left"}}>
          <Typography.Text style={{fontSize: "5vw", color: "#ffffff", fontWeight: "bold"}}>
            Traction so far
          </Typography.Text>
        </div>

        <Card hoverable={false} className='traction-card scroll'  title={false} bordered={false}> 
          <Space direction='vertical' size={"large"}>
            <Space direction="horizontal" size="large">
              <Statistic 
                title={
                <>
                  Grants &nbsp;
                  <Tooltip title="Click to open">
                    
                  </Tooltip>
                </>
                }            
                value={350}
                formatter={formatter}
                prefix={"€"}
                suffix={"K"}
              /> 
              <Statistic 
                title={
                <>
                  Projects &nbsp;
                  <Tooltip title="Projects we have successfully finished in collaboration with academy and other companies">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
                }            
                value={12}
                formatter={formatter}
              />
              <Statistic 
                title={
                <>
                  Crops &nbsp;
                  <Tooltip title="Crops we are proficient at">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
                }            
                value={8}
                formatter={formatter}
              />  
        
            <Statistic 
              title={
                <>
                  MVP & Tech Sales &nbsp;
                  <Tooltip title="We have offered analytics and insurance products before FieldOps, which basically helped us build the foundation for it.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }            
              value={190}
              formatter={formatter}
              prefix={"€"}
              suffix={"K"}
            /> 
          </Space>
          <Typography.Title style={{textAlign: "left"}} level={3}>
            FieldOps™️ Traction
          </Typography.Title>
          <Space direction="horizontal" size="large">
              <Statistic 
                title={
                <>
                  Leads &nbsp;
                  <Tooltip title="Click to open">
                    
                  </Tooltip>
                </>
                }            
                value={200}
                formatter={formatter}
              /> 
              <Statistic 
                title={
                <>
                  MQL &nbsp;
                  <Tooltip title="Marketing Qualified Leads">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
                }            
                value={80}
                formatter={formatter}
              />
              <Statistic 
                title={
                <>
                  SQL &nbsp;
                  <Tooltip title="Sales Qualified Leads">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
                }            
                value={45}
                formatter={formatter}
              />  

            <Statistic
              title={
                <>
                  Sales Meetings &nbsp;
                  <Tooltip title="Sales meetings we have either set or had">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }            
              value={20}
              formatter={formatter}
            /> 
        
            <Statistic
              title={
                <>
                  Proposals &nbsp;
                  <Tooltip title="Proposal">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }            
              value={8}
              formatter={formatter}
            /> 
            <Statistic
              title={
                <>
                  Close &nbsp;
                  <Tooltip title="Deals where either negotiations have ended and we are either doing a project or pilot with the company">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }            
              value={4}
              formatter={formatter}
            />
          </Space>
        </Space>       
      </Card>
        {/* <Row className="traction-row" style={{padding: 10}} gutter={16}>
          <Col className="traction-col" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card hoverable title="Agriculture" bordered={false}>
            Agriculture is the process of growing crops and raising animals for food and other products we use in our daily lives, like clothes and materials for buildings.
            </Card>
          </Col>
          <Col className="traction-col" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card hoverable title="Contract Farming" bordered={false}>
            Contract farming is when farmers and companies make a deal before the crops are even grown. The companies promise to buy the crops from the farmers, and in return, the farmers agree to follow certain rules and use specific techniques to grow the crops. This helps the farmers because they know they'll have buyers for their crops, and it helps the companies because they get a steady supply of the crops they need
            </Card>
          </Col>
          <Col className="traction-col" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card hoverable title="Investability & Future" bordered={false}>
            When we support contract farming, we encourage more companies to work with farmers, making farming a safer and more reliable investment for everyone involved.
            </Card>
          </Col>
        </Row> */}
    </Layout>
  );
};

export default Traction;